::-webkit-scrollbar {
    @apply w-2 h-2;
}

::-webkit-scrollbar-track {
    @apply opacity-0;
}

::-webkit-scrollbar-thumb {
    @apply bg-gray-900 bg-opacity-15 backdrop-blur-md rounded-full;
}